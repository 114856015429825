import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {ConnectedRouter} from 'connected-react-router'
import {parse, stringify} from 'query-string'
import {QueryParamProvider} from 'use-query-params'
import {ReactRouter5Adapter} from 'use-query-params/adapters/react-router-5'

import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import {Provider} from 'react-redux'
// @ts-ignore
import {PersistGate} from 'redux-persist/integration/react'

import {history, persistor, store} from 'redux/store'

import MainLayout from 'layouts/MainLayout'

import {ErrorsModalContainer} from 'components/ErrorsModal'

import {AccountActivation} from 'pages/AccountActivation'
import {AccountDeleted} from 'pages/AccountDeleted'
import {AccountPhoneActivation} from 'pages/AccountPhoneActivation'
import {Company} from 'pages/Company'
import {DeleteProfileConfirm} from 'pages/DeleteProfileConfirm'
import {ForgotPassword} from 'pages/ForgotPassword'
import {Login} from 'pages/Login'
import {NotFound} from 'pages/NotFound'
import {Register} from 'pages/Register'
import {ResetPassword} from 'pages/ResetPassword'
import {SelectEntity} from 'pages/SelectEntity'
import {SettingsProfile} from 'pages/SettingsProfile'
import {Support} from 'pages/Support'

const queryClient = new QueryClient()

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate
                    loading={null}
                    persistor={persistor}>
                    <ErrorsModalContainer />
                    <ToastContainer
                        autoClose={3000}
                        theme="colored"
                    />
                    <ConnectedRouter history={history}>
                        <QueryParamProvider
                            adapter={ReactRouter5Adapter}
                            options={{
                                searchStringToObject: parse,
                                objectToSearchString: stringify
                            }}>
                            <div className="App">
                                <Switch>
                                    <Redirect
                                        exact
                                        from="/"
                                        to="/settings/profile"
                                    />
                                    <Route
                                        exact
                                        path="/login"
                                        component={Login}
                                    />
                                    <Route
                                        exact
                                        path="/register"
                                        component={Register}
                                    />
                                    <Route
                                        exact
                                        path="/forgot-password"
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        path="/password-confirmation/:resetPasswordUID/:resetPasswordCode"
                                        component={ResetPassword}
                                    />
                                    <Route
                                        path="/account-activation/:activationCode?"
                                        component={AccountActivation}
                                    />
                                    <Route
                                        path="/account-phone-activation"
                                        component={AccountPhoneActivation}
                                    />
                                    <Route
                                        exact
                                        path="/select-entity"
                                        component={SelectEntity}
                                    />
                                    <Route
                                        exact
                                        path="/company"
                                        component={(props: any) => (
                                            <MainLayout>
                                                <Company {...props} />
                                            </MainLayout>
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/settings/profile"
                                        component={(props: any) => (
                                            <MainLayout>
                                                <SettingsProfile {...props} />
                                            </MainLayout>
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/settings/profile/delete"
                                        component={(props: any) => (
                                            <MainLayout>
                                                <DeleteProfileConfirm {...props} />
                                            </MainLayout>
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/account-deleted"
                                        component={AccountDeleted}
                                    />
                                    <Route
                                        exact
                                        path="/support"
                                        component={(props: any) => (
                                            <MainLayout>
                                                <Support {...props} />
                                            </MainLayout>
                                        )}
                                    />
                                    <Route component={NotFound} />
                                </Switch>
                            </div>
                        </QueryParamProvider>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    )
}
