import React, {useMemo} from 'react'
import {connect} from 'react-redux'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {useQueryResourceList} from 'queries'

import {Card} from 'components/Card'
import {PageLoader} from 'components/PageLoader'

import {EntityProfileDetails} from './partials'

import './Company.scss'

type CompanyProps = {
    selectedEntityID: string
}

export const Company = ({selectedEntityID}: CompanyProps) => {
    const {data: entityProfilesData, isFetching: isFetchingEntityProfiles} = useQueryResourceList(
        RESOURCES.entityProfiles,
        {entity_id: selectedEntityID}
    )

    const entityProfiles = useMemo(() => {
        return (entityProfilesData as any)?.data?.results
    }, [entityProfilesData])

    if (isFetchingEntityProfiles) {
        return <PageLoader />
    }

    return (
        <div className="company-page">
            <Card title="Utilizatori companie">
                <div className="entity-profiles-card-content-container">
                    {entityProfiles.map((entityProfile: any) => (
                        <EntityProfileDetails
                            entityProfile={entityProfile}
                            key={entityProfile.id}
                        />
                    ))}
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

// const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps)(Company)
