import React, {useCallback} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'

// @ts-ignore
import {classNames, isCurrentNavigationItem} from '../../utils'

interface NavigationItemComponentProps {
    item: any
}

export default function NavigationItemComponent({item}: NavigationItemComponentProps) {
    const location = useLocation()

    const renderIcon = useCallback(() => {
        if (item?.icon) {
            return (
                <item.icon
                    className="mr-3 h-6 w-6 text-indigo-900"
                    aria-hidden="true"
                />
            )
        }
    }, [item])

    return (
        <Link
            to={item.path}
            className={classNames(
                isCurrentNavigationItem(location, item.path)
                    ? 'bg-gray-200 text-indigo-900'
                    : 'text-neutral-800 hover:bg-gray-300',
                'group flex items-center px-2 py-2 text-md font-bold rounded-md'
            )}>
            {renderIcon()}
            <p>{item.name}</p>
        </Link>
    )
}
