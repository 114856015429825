import React from 'react'

import _ from 'lodash'

// @ts-ignore
import {getErrorMessage} from '../../utils/errorHelper'

import './ErrorsList.scss'

interface ErrorsListProps {
    errors: any[]
}

export default function ErrorsList({errors}: ErrorsListProps) {
    if (!_.isEmpty(errors)) {
        return (
            <div className="errors-list">
                {errors.map((error, index) => (
                    <ErrorComponent
                        message={getErrorMessage(error)}
                        key={index}
                    />
                ))}
            </div>
        )
    }

    return null
}

interface ErrorsComponentProps {
    message: string
}

export function ErrorComponent({message}: ErrorsComponentProps) {
    return (
        <div className="error-div">
            <p className="error-text">{message}</p>
        </div>
    )
}
