import React from 'react'

import {Form, Formik} from 'formik'

// @ts-ignore
import {loginOTP} from 'avoapp-react-common/dist/redux/auth'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from 'redux/modals'

import {Button} from 'components/Button'
import {ErrorsList} from 'components/ErrorComponents'
import {Input} from 'components/Input'
import {Modal} from 'components/Modal'

interface OTPLoginModalProps {
    open: boolean
    isLoading: boolean
    closeModal: () => void
    loginOTP: (values: any) => void
    fieldErrors: any
    nonFieldErrors: any
}

export const OTPLoginModal = ({
    open,
    isLoading,
    closeModal,
    loginOTP,
    fieldErrors,
    nonFieldErrors
}: OTPLoginModalProps) => {
    return (
        <Modal
            open={open}
            title="Autentificare SMS">
            <>
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{
                        token: ''
                    }}
                    onSubmit={(values) => {
                        loginOTP(values)
                    }}>
                    {({handleSubmit, handleChange, handleBlur, values, errors, touched}) => (
                        <Form className="edit-task-time-log-form-container">
                            <div className="textarea-container">
                                <Input
                                    label="Cod primit prin SMS"
                                    value={values.token}
                                    onChange={handleChange('token')}
                                    name="token"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.token}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </div>

                            <div className="buttons-container">
                                <Button
                                    title="Trimite codul"
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    color="primary"
                                />
                                <Button
                                    title="Renunță"
                                    onClick={closeModal}
                                    variant="outlined"
                                    loading={isLoading}
                                    color="gray"
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.OTP,
    isLoading: state.auth.isLoading,
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal()),
    loginOTP: (values: any) => dispatch(loginOTP(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(OTPLoginModal)
