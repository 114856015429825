import React, {ReactNode} from 'react'

import './Card.scss'

interface CardProps {
    title: string
    children: ReactNode
}

export default function Card({title, children}: CardProps) {
    return (
        <div className="adv-card-container">
            <div className="adv-card-title-container">
                <h3 className="adv-card-title">{title}</h3>
            </div>
            <div className="adv-card-content-container">{children}</div>
        </div>
    )
}
