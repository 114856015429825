import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
    format,
    lightFormat,
    parseISO,
    setHours,
    setMinutes,
    setSeconds
} from 'date-fns'
import {ro} from 'date-fns/locale'

import {
    filter,
    forEach,
    has,
    head,
    isArray,
    isEmpty,
    isNil,
    isNull,
    isUndefined,
    join,
    snakeCase,
    split,
    startsWith,
    tail,
    padStart,
    toUpper
} from 'lodash'

export const env = process.env.NODE_ENV

export const replaceNullWithDash = (value) => {
    if (isEmpty(value) || isNull(value) || isUndefined(value)) {
        return '-'
    }

    return value
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const isCurrentNavigationItem = (location, path) => {
    const {pathname: currentPath} = location
    return startsWith(currentPath, path)
}

export const objectKeysToSnakeCase = (object) => {
    let newObject = {}

    for (var key in object) {
        if (isArray(object[key])) {
            newObject[snakeCase(key)] = []

            forEach(object[key], (element, idx) => {
                let newSecondLevelObject = {}

                for (var secondKey in element) {
                    newSecondLevelObject[snakeCase(secondKey)] = object[key][idx][secondKey]
                }

                newObject[snakeCase(key)].push(newSecondLevelObject)
            })
        } else {
            newObject[snakeCase(key)] = object[key]
        }
    }

    return newObject
}

export const getEntityProfilesWithClientPermissions = (client, role) => {
    const permissionsWithRole = filter(client.client_permissions, ['role', role])

    if (!isEmpty(permissionsWithRole)) {
        const entityProfilesWithRole = permissionsWithRole.map((permission) => permission.entity_profile)

        return join(
            entityProfilesWithRole.map((profile) => `${profile.first_name} ${profile.last_name}`),
            ', '
        )
    }

    return []
}

export const getEntityProfilesWithProjectPermissions = (permissions, role) => {
    const permissionsWithRole = filter(permissions, ['role', role])

    if (!isEmpty(permissionsWithRole)) {
        const entityProfilesWithRole = permissionsWithRole.map((permission) => permission.entity_profile)

        return join(
            entityProfilesWithRole.map((profile) => `${profile.first_name} ${profile.last_name}`),
            ', '
        )
    }

    return []
}

export const splitStringIntoNames = (string) => {
    const names = split(string, /(?<=^\S+)\s/) //split only on first space occurance
    const lastName = head(names)
    const firstName = tail(names)

    return {lastName, firstName}
}

export const getTimePassedFromDate = (date) => {
    const diff = differenceInDays(new Date(), new Date(date))

    if (diff === 0) return 'Astăzi'

    if (diff < 7) return `Acum ${diff} zile`

    return lightFormat(new Date(date), 'dd/MM/yyyy')
}

export const zeroFill = (num) => (num < 10 ? `0${num}` : num)

export const getIsoFromTime = (date, time) => parseISO(`${date}T${time}`)

export const getDiffTime = (log) => {
    if (isNull(log.start) || isNull(log.stop)) {
        return '-'
    }

    const startISO = getIsoFromTime(log.date, log.start)
    const stopISO = getIsoFromTime(log.date, log.stop)

    const sec = differenceInSeconds(stopISO, startISO)

    if (sec < 60) {
        return `${sec} sec.`
    }

    const min = differenceInMinutes(stopISO, startISO)
    const secRemain = sec % 60

    const hour = differenceInHours(stopISO, startISO)
    const minRemain = min % 60

    return `${zeroFill(hour)}:${zeroFill(minRemain)}:${zeroFill(secRemain)}`
}

export const removeExtension = (string) => head(split(string, '.', 2))

export const roLocaleFormat = (date, pattern) => format(date, pattern, {locale: ro})

export const upperSnakeCase = (string) => toUpper(snakeCase(string))

export const hasWhiteSpace = (s) => {
    const reWhiteSpace = new RegExp('\\s+')

    return reWhiteSpace.test(s)
}

export const setTime = (date, h, m, s) => setHours(setMinutes(setSeconds(new Date(date), s), m), h)

export const toApiTimeFormat = (date, withoutSeconds = false) => {
    const formatStr = withoutSeconds ? 'HH:mm' : 'HH:mm:ss'

    if (!isNil(date) && date !== '') {
        return format(new Date(date), formatStr)
    }

    return null
}

export const toApiDateFormat = (date) => {
    if (!isNil(date) && date !== '') {
        return lightFormat(new Date(date), 'yyyy-MM-dd')
    }

    return null
}

export const padZero = (str, len) => {
    len = len || 2
    var zeros = new Array(len).join('0')
    return (zeros + str).slice(-len)
}

export const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1)
    }

    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.')
    }

    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16)

    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
    }

    // invert color components
    r = (255 - r).toString(16)
    g = (255 - g).toString(16)
    b = (255 - b).toString(16)

    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b)
}

export const getFieldOptions = (resourceOptions, field) => {
    if (!isEmpty(resourceOptions) && has(resourceOptions, field)) {
        return resourceOptions[field].choices.map((choice) => ({
            value: choice.value,
            label: choice.display_name
        }))
    }
}

export const roundHalfUp = (number) => {
    // https://pawelgrzybek.com/rounding-and-truncating-numbers-in-javascript/#rounding-numbers-in-javascript
    return Math.round(number * 100) / 100
}

export const toReadableDuration = (duration) => {
    const daysSplit = split(duration, ' ')
    const days = parseInt(head(split))

    const dayString = days === 1 ? 'Zi' : 'Zile'

    if (days > 0) {
        return `${days} ${dayString} ${tail(daysSplit)}`
    }

    return tail(daysSplit)
}

export const durationToFloat = (duration) => {
    if (isNil(duration) || duration === '') {
        return 0
    }

    const daysSplit = split(duration, ' ')
    const timeSplit = split(tail(daysSplit), ':')

    const days = parseInt(head(daysSplit))
    const hours = parseInt(timeSplit[0]) + parseInt(days * 24)
    const minutes = parseInt(timeSplit[1])
    const seconds = parseInt(timeSplit[2])

    return roundHalfUp(hours + parseFloat(minutes / 60) + parseFloat(seconds / 3600))
}

export const durationAsHoursAndMinutes = (duration) => {
    if (isNil(duration) || duration === '') {
        return '00:00'
    }

    const daysSplit = split(duration, ' ')
    const timeSplit = split(tail(daysSplit), ':')

    const days = parseInt(head(daysSplit))
    const hours = parseInt(timeSplit[0]) + parseInt(days * 24)
    const minutes = parseInt(timeSplit[1])

    const formattedHours = padStart(hours.toString(), 2, '0')
    const formattedMinutes = padStart(minutes.toString(), 2, '0')

    return `${formattedHours}:${formattedMinutes}`
}
