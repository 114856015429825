import {Bars3BottomLeftIcon, BellIcon} from '@heroicons/react/24/outline'
import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'

import './Navbar.scss'

interface NavbarProps {
    setSidebarOpen: (state: boolean) => void
}

export default function Navbar({setSidebarOpen}: NavbarProps) {
    const openSidebar = useCallback(() => {
        setSidebarOpen(true)
    }, [setSidebarOpen])

    return (
        <div className="navbar-container">
            <button
                className="hamburger-menu-button"
                onClick={openSidebar}>
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon
                    className="navbar-icon"
                    aria-hidden="true"
                />
            </button>
            <div className="navbar-content-container">
                <div className="left-placeholder" />
                <div className="right-navbar-container">
                    <Link
                        to="/notifications"
                        className="notifications-link">
                        <span className="sr-only">View notifications</span>
                        <BellIcon
                            className="navbar-icon"
                            aria-hidden="true"
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}
