import React, {ReactNode} from 'react'

import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {closeErrorsModal} from 'redux/errorsModal'

import {Button} from 'components/Button'
import {Modal} from 'components/Modal'

import './ErrorsModal.scss'

interface ErrorsModalProps {
    open: boolean
    messageContent: () => ReactNode
    contactAvailable?: boolean
    afterClose?: () => void
    closeErrorsModal: () => void
    navigate: (target: string) => void
}

export const ErrorsModal = ({open, messageContent, contactAvailable, closeErrorsModal, navigate}: ErrorsModalProps) => {
    return (
        <Modal open={open}>
            <div className="avo-errors-modal-content-container">
                <div className="avo-errors-modal-message-container">{messageContent()}</div>
                <div className="avo-errors-modal-buttons-container">
                    {contactAvailable && (
                        <Button
                            title="Contactează-ne"
                            onClick={() => {
                                navigate('/support')
                                closeErrorsModal()
                            }}
                            fullWidth
                        />
                    )}
                    <Button
                        title="Ok"
                        onClick={closeErrorsModal}
                        fullWidth
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeErrorsModal: () => dispatch(closeErrorsModal()),
    navigate: (target: string) => dispatch(push(target))
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsModal)
