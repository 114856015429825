import * as Yup from 'yup'

export const validationSchema = {
    register: Yup.object().shape({
        email: Yup.string().email('Adresa de email nu e validă!').required('Adresa de email e obligatorie!'),
        password: Yup.string().label('Parola').required('Parola e obligatorie!'),
        lastName: Yup.string().label('Nume').required('Numele este obligatoriu!'),
        firstName: Yup.string().label('Prenume').required('Prenumele este obligatoriu!'),
        terms: Yup.boolean().oneOf([true], 'Este nevoie să accepți termenii și condițiile pentru a crea un cont')
    }),
    login: Yup.object().shape({
        email: Yup.string().email('Adresa de email nu e validă!').required('Adresa de email e obligatorie!'),
        password: Yup.string().label('Parola').required('Parola e obligatorie!')
    }),
    forgotPassword: Yup.object().shape({
        email: Yup.string().email('Adresa de email nu e validă!').required('Adresa de email e obligatorie!')
    }),
    resetPassword: Yup.object().shape({
        new_password1: Yup.string().label('Parola').required('Parola e obligatorie!'),
        new_password2: Yup.string()
            .label('Confirmare parolă')
            .oneOf([Yup.ref('new_password1'), null], 'Parolele nu sunt la fel!')
    })
}
