import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'

import {TrashIcon} from '@heroicons/react/24/outline'

import {connect} from 'react-redux'

import {Button} from 'components/Button'
import {Card} from 'components/Card'

export const SettingsProfile = ({user}: {user: any}) => {
    const history = useHistory()

    const navigateToDeleteAccount = useCallback(() => {
        history.push('/settings/profile/delete')
    }, [history])

    return (
        <div className="page-info">
            <Card title="Ștergere cont">
                <p>
                    Contul tău Advocatus îți poate oferi acces la diferite aplicații din suita de aplicații oferite de
                    Advocatus.
                    <br />
                    Printre acestea, se numără: Monitor Dosare, Monitor BPI, AvoApp
                </p>
            </Card>
            <div className="flex gap-5 justify-between mt-5">
                <Card title="Contul tău">
                    <ul>
                        <li>Email: {user.email}</li>
                        <li>Nume: {user.first_name}</li>
                        <li>Prenume: {user.last_name}</li>
                        <li>Telefon: {user.phone}</li>
                    </ul>
                </Card>
                <Card title="Aplicații la care ai acces">
                    <ul>
                        <li>Monitor Dosare</li>
                        <li>Monitor BPI</li>
                        {user.has_avoapp_access && <li>AvoApp</li>}
                    </ul>
                </Card>
            </div>
            <div className="flex flex-wrap w-full justify-end">
                <Button
                    title="Șterge"
                    onClick={navigateToDeleteAccount}
                    icon={() => <TrashIcon className="h-5 w-5 mr-2" />}
                    color="red"
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state.users.user
})

export default connect(mapStateToProps)(SettingsProfile)
