import React, {useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom'

import {Form, Formik} from 'formik'
import queryString from 'query-string'

// @ts-ignore
import {register} from 'avoapp-react-common/dist/redux/auth'
import {connect} from 'react-redux'

// @ts-ignore
import {authSchema} from '../../assets/validations'

import {Button} from 'components/Button'
import {Checkbox} from 'components/Checkbox'
import {ErrorsList} from 'components/ErrorComponents'
import {Input} from 'components/Input'
import {PhoneInput} from 'components/PhoneInput'
import {RequiredFieldsText} from 'components/RequiredFieldsText'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './Register.scss'

interface RegisterProps {
    fieldErrors: any
    nonFieldErrors: any
    isLoading: boolean
    register: (values: any) => void
    signupSuccess: boolean
    location: Location
}
export const Register = ({
    fieldErrors,
    nonFieldErrors,
    isLoading,
    register,
    signupSuccess,
    location
}: RegisterProps) => {
    const history = useHistory()
    const queryParams = queryString.parse(location.search)

    if (queryParams.redirect) {
        const url = Array.isArray(queryParams.redirect) ? queryParams.redirect[0] : queryParams.redirect
        localStorage.setItem('redirectURL', url)
    }

    useEffect(() => {
        if (signupSuccess) {
            history.push('/account-activation')
        }
    }, [history, signupSuccess])

    return (
        <div className="register-container">
            <div className="header-container">
                <img
                    src={logo}
                    className="logo"
                    alt="Advocatus"
                />
                <h2 className="title">Creează un cont Advocatus</h2>
            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                            firstName: '',
                            lastName: '',
                            phone: '+40',
                            terms: false
                        }}
                        validationSchema={authSchema.register}
                        onSubmit={(values) => {
                            register({
                                email: values.email,
                                password1: values.password,
                                password2: values.password,
                                first_name: values.firstName,
                                last_name: values.lastName,
                                phone: `+${values.phone}`
                            })
                        }}>
                        {({handleChange, handleBlur, handleSubmit, values, errors, touched, isValid}) => (
                            <Form className="space-y-6">
                                <Input
                                    label="Adresa de email*"
                                    value={values.email}
                                    onChange={handleChange('email')}
                                    onBlur={handleBlur('email')}
                                    name="email"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.email}
                                    autoComplete="email"
                                    type="email"
                                    fullWidth
                                />
                                <Input
                                    label="Parola*"
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    onBlur={handleBlur('password')}
                                    name="password"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.password}
                                    autoComplete="password"
                                    type="password"
                                    fullWidth
                                    convertToSnakeCases={false}
                                />
                                <Input
                                    label="Nume*"
                                    value={values.lastName}
                                    onChange={handleChange('lastName')}
                                    onBlur={handleBlur('lastName')}
                                    name="lastName"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.lastName}
                                    fullWidth
                                />
                                <Input
                                    label="Prenume*"
                                    value={values.firstName}
                                    onChange={handleChange('firstName')}
                                    onBlur={handleBlur('firstName')}
                                    name="firstName"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.firstName}
                                    fullWidth
                                />
                                <PhoneInput
                                    label="Telefon*"
                                    value={values.phone}
                                    onChange={handleChange('phone')}
                                    name="phone"
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                <Checkbox
                                    checked={values.terms}
                                    label={() => (
                                        <>
                                            Sunt de acord cu{' '}
                                            <a
                                                href={`${window.location.origin}/terms-and-conditions`}
                                                className="terms-link"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Termenii și Condițiile*
                                            </a>
                                        </>
                                    )}
                                    onChange={handleChange('terms')}
                                    onBlur={handleBlur('terms')}
                                    name="terms"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.terms}
                                    fullWidth
                                />
                                <RequiredFieldsText />
                                <div>
                                    <Button
                                        title="Creează cont"
                                        disabled={!isValid}
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        fullWidth
                                    />

                                    <div className="back-to-login-container">
                                        <Link
                                            to="/login"
                                            className="back-to-login-link">
                                            Înapoi la login
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors,
    isLoading: state.auth.isLoading,
    signupSuccess: state.auth.signupSuccess
})

const mapDispatchToProps = (dispatch: any) => ({
    register: (values: any) => dispatch(register(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
