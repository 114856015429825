import React from 'react'

import {connect} from 'react-redux'
import {ErrorModalType, errorsModalTypes} from 'redux/errorsModal'

import {Error500Modal, ForbiddenModal, GeneralErrorModal, NotFoundModal} from './partials'

interface ErrorsModalContainerProps {
    errorModalType: ErrorModalType
}

export const ErrorsModalContainer = ({errorModalType}: ErrorsModalContainerProps) => {
    switch (errorModalType) {
        case errorsModalTypes.GENERAL_ERROR:
            return <GeneralErrorModal />
        case errorsModalTypes.ERROR_500:
            return <Error500Modal />
        case errorsModalTypes.FORBIDDEN:
            return <ForbiddenModal />
        case errorsModalTypes.NOT_FOUND:
            return <NotFoundModal />
        default:
            return <React.Fragment />
    }
}

const mapStateToProps = (state: any) => ({
    errorModalType: state.errorsModal.type
})

export default connect(mapStateToProps)(ErrorsModalContainer)
