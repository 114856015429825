import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {Form, Formik} from 'formik'

// @ts-ignore
import {loginOTP} from 'avoapp-react-common/dist/redux/auth'

import {Button} from 'components/Button'
import {Input} from 'components/Input'
import {Loader} from 'components/Loader'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './AccountPhoneActivation.scss'

interface AccountPhoneActivationProps {
    isLoading: boolean
    signupSuccess: boolean
    loginOTP: (token: string) => void
}

const AccountPhoneActivation = ({isLoading, signupSuccess, loginOTP}: AccountPhoneActivationProps) => {
    const history = useHistory()

    useEffect(() => {
        if (signupSuccess) {
            const redirectURL = localStorage.getItem('redirectURL') // http://localhost:3002
            localStorage.removeItem('redirectURL')
            if (redirectURL) {
                window.location.href = redirectURL
            } else {
                history.push('/')
            }
        }
    }, [history, signupSuccess])

    return (
        <div className="account-phone-activation-container">
            <div className="main-container">
                <div className="header">
                    <img
                        src={logo}
                        className="logo"
                        alt="Advocatus"
                    />
                    <h1 className="title">Activare cont</h1>
                    <p className="check-phone">
                        Vă rugăm introdceți codul de activare primit prin SMS pe dumneavoastră de telefon
                    </p>
                </div>
                <div className="content">
                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <Formik
                            initialValues={{activationCode: ''}}
                            onSubmit={(values) => {
                                loginOTP(values.activationCode)
                            }}>
                            {({handleChange, handleBlur, handleSubmit, values, errors, touched, isValid}) => (
                                <Form className="activation-form">
                                    <Input
                                        label="Cod de activare"
                                        value={values.activationCode}
                                        onChange={handleChange('activationCode')}
                                        onBlur={handleBlur('activationCode')}
                                        name="activationCode"
                                        errors={errors}
                                        touched={touched.activationCode}
                                        fullWidth
                                    />
                                    <Button
                                        title="Confirmă"
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        fullWidth
                                    />
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </div>
    )
}

const LoadingComponent = () => (
    <div className="account-activation">
        <p className="activation-message">Așteaptă salvarea răspunsului tău</p>
        <Loader size="large" />
    </div>
)

const mapStateToProps = (state: any) => ({
    isLoading: state.auth.isLoading,
    signupSuccess: state.auth.signupSuccess
})

const mapDispatchToProps = (dispatch: any) => ({
    loginOTP: (token: string) => dispatch(loginOTP({token: token}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPhoneActivation)
