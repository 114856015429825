import {LOCATION_CHANGE} from 'connected-react-router'

export function reducer(state = [], action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return [...state, action.payload]
        default:
            return state
    }
}
