import React, {ReactNode} from 'react'

import {FormikTouched} from 'formik'

import _ from 'lodash'

// @ts-ignore
import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from 'components/ErrorComponents'

import './Input.scss'

interface InputProps {
    label: string
    placeholder?: string
    value: string | number
    onChange: React.ChangeEventHandler<HTMLInputElement>
    size?: 'small' | 'medium' | 'large'
    type?: React.HTMLInputTypeAttribute
    required?: boolean
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    fullWidth?: boolean
    disabled?: boolean
    autoComplete?: string
    defaultValue?: string | number
    onClick?: () => void
    renderIcon?: () => ReactNode
    name: string
    errors?: any
    frontendErrors?: any
    min?: string | number
    max?: string | number
    dataTest?: string
    style?: React.CSSProperties
    className?: string
    convertToSnakeCases?: boolean
}

export default function Input({
    label,
    placeholder,
    autoComplete = 'off',
    required,
    value,
    defaultValue,
    onChange,
    onBlur,
    onClick,
    size = 'medium',
    fullWidth,
    renderIcon,
    name,
    errors,
    frontendErrors,
    touched,
    type = 'text',
    min,
    max,
    disabled,
    dataTest,
    style,
    className,
    convertToSnakeCases
}: InputProps) {
    const snakeCaseName: string = convertToSnakeCases ? _.snakeCase(name) : name
    const camelCaseName = _.camelCase(name)

    const hasErrors =
        (errors && !_.isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !_.isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !_.isEmpty(errors[snakeCaseName])
            ? getErrorMessage(_.head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div className={`avo-input-label-container ${fullWidth ? 'full-width' : ''} ${className || ''}`}>
            {label && <label className="avo-label">{label}</label>}
            <div className={`avo-input-wrapper ${size}`}>
                {renderIcon && <div className="input-icon-wrapper">{renderIcon()}</div>}
                <input
                    value={value}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    className={`avo-input ${renderIcon ? 'has-icon' : ''}`}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    required={required || false}
                    style={style}
                    min={type === 'number' ? min : undefined}
                    max={type === 'number' ? max : undefined}
                    type={type}
                    data-test={dataTest}
                    disabled={disabled}
                />
            </div>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
