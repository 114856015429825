import React, {useCallback} from 'react'

import {Loader} from '../Loader'

import {BottonProps} from './model'

import './Button.scss'

export default function Button({
    onClick,
    variant = 'contained',
    size = 'medium',
    color = 'primary',
    title,
    type,
    icon,
    iconLeft,
    disabled,
    loading,
    fullWidth,
    disableTabSelect,
    className,
    dataTest
}: BottonProps) {
    const renderLoadingOrIcon = useCallback(() => {
        const className = `avo-button-icon-container ${title ? 'has-title' : 'icon-only'}`
        if (loading) {
            return (
                <div className={className}>
                    <Loader size="small" />
                </div>
            )
        }

        if (icon !== undefined) {
            return <div className={className}>{icon()}</div>
        }
    }, [icon, loading, title])

    return (
        <button
            onClick={onClick}
            className={
                `avo-button ${variant} ${size} ${color} ` +
                `${fullWidth ? 'full-width' : ''} ` +
                `${iconLeft ? 'icon-left' : ''} ` +
                `${className || ''}`
            }
            disabled={disabled || loading}
            tabIndex={disableTabSelect ? -1 : undefined}
            type={type || 'button'}
            data-test={dataTest}>
            {title ? title : null}
            {renderLoadingOrIcon()}
        </button>
    )
}
