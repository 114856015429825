import React from 'react'
import {Link} from 'react-router-dom'

import {Form, Formik} from 'formik'

// @ts-ignore
import {forgotPassword} from 'avoapp-react-common/dist/redux/auth'
import {connect} from 'react-redux'

// @ts-ignore
import {authSchema} from '../../assets/validations'

import {Button} from 'components/Button'
import {ErrorsList} from 'components/ErrorComponents'
import {Input} from 'components/Input'
import {NoAccount} from 'components/NoAccount'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './ForgotPassword.scss'

interface ForgotPasswordProps {
    fieldErrors: any
    nonFieldErrors: any
    isLoading: boolean
    forgotPassword: (values: any) => void
}

export const ForgotPassword = ({fieldErrors, nonFieldErrors, isLoading, forgotPassword}: ForgotPasswordProps) => {
    return (
        <div className="forgot-password-container">
            <div className="main-container">
                <div className="header">
                    <img
                        src={logo}
                        className="logo"
                        alt="Advocatus"
                    />
                    <h1 className="title">Resetare parolă</h1>
                    <p>
                        Pentru a reseta parola contului tău, introdu adresa de email și iți vom trimite un link de
                        resetare.
                    </p>
                </div>
                <div className="m-6 mt-3">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{email: ''}}
                        validationSchema={authSchema.forgotPassword}
                        onSubmit={(values) => forgotPassword(values)}>
                        {({handleChange, handleBlur, values, handleSubmit, errors, touched, isValid}) => (
                            <Form className="space-y-6">
                                <Input
                                    value={values.email}
                                    label="Adresa de email"
                                    onChange={handleChange('email')}
                                    onBlur={handleBlur('email')}
                                    name="email"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.email}
                                    autoComplete="email"
                                    type="email"
                                    fullWidth
                                />
                                <Button
                                    title="Trimite link de resetare"
                                    disabled={!isValid}
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                    <div className="back-to-login-container">
                        <Link
                            to="/login"
                            className="back-to-login-link">
                            Înapoi la login
                        </Link>
                    </div>
                    <NoAccount />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
    forgotPassword: (values: any) => dispatch(forgotPassword(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
