import React from 'react'
import {Link} from 'react-router-dom'

import './NotFound.scss'

export default function NotFound() {
    return (
        <div className="not-found-container">
            <div className="max-w-max mx-auto">
                <main className="sm:flex">
                    <p className="not-found-code-text">404</p>
                    <div className="sm:ml-6">
                        <div className="text-container">
                            <h1 className="page-not-found-text">Pagina nu a fost găsită</h1>
                            <p className="check-url-text">Te rugăm verifică adresa URL și încearcă din nou.</p>
                        </div>
                        <div className="link-container">
                            <Link
                                to="/"
                                className="go-home-link">
                                Înapoi acasă
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
