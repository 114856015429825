import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {errorsModalTypes} from '../../../redux/errorsModal'

import ErrorsModal from '../ErrorsModal'
import {ErrorModalProps} from '../model'

interface GeneralErrorModalProps extends ErrorModalProps {
    message: string
}

export const GeneralErrorModal = ({open, message}: GeneralErrorModalProps) => {
    return (
        <ErrorsModal
            open={open}
            messageContent={() => (
                <p className="avo-errors-modal-message">{!isEmpty(message) ? message : 'A apărut o eroare'}</p>
            )}
        />
    )
}

const mapStateToProps = (state: any) => ({
    open: state.errorsModal.type === errorsModalTypes.ERROR_500,
    message: state.errorsModal.message
})

export default connect(mapStateToProps)(GeneralErrorModal)
