import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'

import {Dialog, Transition} from '@headlessui/react'
import {XMarkIcon} from '@heroicons/react/24/outline'

// @ts-ignore
import {classNames} from '../../utils'

import NavigationItemComponent from './NavigationItemComponent'
import ProfileSection from './ProfileSection'

import {navigation, secondaryNavigation} from './constants'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './Sidebar.scss'

interface SidebarProps {
    sidebarOpen: boolean
    setSidebarOpen: (state: boolean) => void
}

export default function Sidebar({sidebarOpen, setSidebarOpen}: SidebarProps) {
    return (
        <>
            <Transition.Root
                show={sidebarOpen}
                as={Fragment}>
                <Dialog
                    as="div"
                    static
                    className="fixed inset-0 flex z-40 md:hidden"
                    open={sidebarOpen}
                    onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transform transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transform ease-in-out duration-300"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform ease-in-out duration-300"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full">
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-primary">
                            <Transition.Child
                                as={Fragment}
                                enter="transform ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transform ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        className={classNames(
                                            'ml-1 flex items-center justify-center h-10 w-10 rounded-full',
                                            'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                                        )}
                                        onClick={() => setSidebarOpen(false)}>
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                <div className="flex-shrink-0 flex items-center justify-center px-4">
                                    <img
                                        className="h-8 w-auto"
                                        src={logo}
                                        alt="Advocatus"
                                    />
                                </div>
                                <nav
                                    aria-label="Sidebar"
                                    className="mt-5 flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto">
                                    <div className="px-2 space-y-1">
                                        {navigation.map((item) => (
                                            <NavigationItemComponent
                                                key={item.name}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                    <div className="mt-6 pt-6">
                                        <div className="px-2 space-y-1">
                                            {secondaryNavigation.map((item) => (
                                                <NavigationItemComponent
                                                    key={item.name}
                                                    item={item}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </nav>
                            </div>
                            <ProfileSection />
                        </div>
                    </Transition.Child>
                    <div
                        className="flex-shrink-0 w-14"
                        aria-hidden="true">
                        {/* Force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="sidebar-desktop">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col h-0 flex-1">
                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <Link to="/">
                                <div className="items-center justify-center flex-shrink-0 px-4">
                                    <img
                                        className="h-auto w-full"
                                        src={logo}
                                        alt="Advocatus"
                                    />
                                </div>
                            </Link>
                            <nav
                                aria-label="Sidebar"
                                className="mt-5 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto">
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <NavigationItemComponent
                                            key={item.name}
                                            item={item}
                                        />
                                    ))}
                                </div>
                                <div className="mt-6 pt-6">
                                    <div className="px-2 space-y-1">
                                        {secondaryNavigation.map((item) => (
                                            <NavigationItemComponent
                                                key={item.name}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <ProfileSection />
                    </div>
                </div>
            </div>
        </>
    )
}
