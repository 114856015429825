import {isNull, snakeCase} from 'lodash'

const errors: {[key: string]: string} = {
    invalid: 'Câmpul nu este valid',
    unique: 'Câmpul trebuie sa fie unic',
    null: 'Acest câmp este obligatoriu',
    blank: 'Acest câmp este obligatoriu',
    incorrect: 'Date incorecte',

    //
    // email_invalid: 'Există deja un utilizator înregistrat cu această adresă de email.',
    login_invalid_credentials: 'Email-ul sau parola sunt greșite',
    too_many_devices:
        'Ai depășit limita de dispozitive utilizate în cadrul abonamentului tău. Te rugăm să ne contactezi.',
    email_not_verified: 'Cont neactivat. Am retrimis chiar acum un link de activare pe adresa ta de email.',
    does_not_exist: 'Nu există.',
    invalid_phone_number: 'Numărul de telefon introdus nu este valid.',
    email_invalid: 'Adresă de email nu este validă.',
    password_1_password_too_short: 'Parola trebuie să conțină cel puțin 8 caractere.',
    password_1_password_too_common: 'Parola este prea comună.',
    password_1_password_entirely_numeric: 'Parola nu poate conține doar cifre.',
    password_2_password_too_short: 'Parola trebuie să conțină cel puțin 8 caractere.',
    password_2_password_too_common: 'Parola este prea comună.',
    password_2_password_entirely_numeric: 'Parola nu poate conține doar cifre.',
    phone_invalid: 'Numărul de telefon introdus nu este valid.',
    name_blank: 'Numele este obligatoriu.',
    email_blank: 'Adresa de email este obligatorie.',
    phone_blank: 'Numărul de telefon este obligatoriu.',
    county_blank: 'Județul este obligatoriu.',
    locality_blank: 'Localitatea este obligatorie.',
    address_blank: 'Adresa este obligatorie.',
    iban_blank: 'IBAN-ul este obligatoriu.',
    postal_code_required: 'Codul poștal e obligatoriu.',
    address_description_required: 'Descrierea adresei este obligatorie.',
    address_description_blank: 'Descrierea adresei este obligatorie.',
    vat_code_blank: 'CUI/CNP e obligatoriu.',
    bank_name_blank: 'Numele băncii e obligatoriu.',
    swift_code_blank: 'Codul SWIFT e obligatoriu.',
    bank_account_description_blank: 'Descrierea contului bancar e obligatorie.',
    name_too_similar: 'Există deja un client cu un nume asemănător.',
    unique_name: 'Exista deja un client cu acest nume.',
    supervisor_id_null: 'Supraveghetorul este obligatoriu.'
}

export const getErrorMessage = (error: any, fieldName: string | null = null) => {
    const errorKey: string = !isNull(fieldName) ? snakeCase(`${fieldName} ${error.code}`) : error.code

    if (errors[errorKey]) {
        return errors[errorKey]
    } else {
        console.warn(`New error with code ${errorKey}`, error)
    }

    return error.message
}
