import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    date: Yup.date().label('Data start').nullable().required('Data este obligatorie'),
    amount: Yup.number()
        .transform((o, v) => parseFloat(v.replace(/,/g, '')))
        .label('Suma')
        .nullable()
        .required('Suma este obligatorie!'),
    toBePaid: Yup.boolean()
})
