// @ts-ignore
import {Action} from 'avoapp-react-common/dist/redux'
// @ts-ignore
import {ACTIONS as ERRORS_ACTIONS} from 'avoapp-react-common/dist/redux/errors'

export const ACTIONS = {
    CLOSE_ERRORS_MODAL: new Action('CLOSE_ERRORS_MODAL')
}

export const errorsModalTypes = {
    NOT_FOUND: 'NOT_FOUND',
    FORBIDDEN: 'FORBIDDEN',
    TOOL_UNAVAILABLE: 'TOOL_UNAVAILABLE',
    ERROR_500: 'ERROR_500',
    GENERAL_ERROR: 'GENERAL_ERROR'
}

export type ErrorModalType = (typeof errorsModalTypes)[keyof typeof errorsModalTypes]

const initialState = {
    type: null,
    message: ''
}

export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case ERRORS_ACTIONS.GENERAL_ERROR.main:
            return {
                type: errorsModalTypes.GENERAL_ERROR,
                message: action.payload.message
            }
        case ERRORS_ACTIONS.FORBIDDEN.main:
            return {
                type: errorsModalTypes.FORBIDDEN
            }
        case ERRORS_ACTIONS.NOT_FOUND.main:
            return {
                type: errorsModalTypes.NOT_FOUND
            }
        case ERRORS_ACTIONS.ERROR_500.main:
            return {
                type: errorsModalTypes.ERROR_500
            }
        case ERRORS_ACTIONS.TOOL_UNAVAILABLE.main:
            return {
                type: errorsModalTypes.TOOL_UNAVAILABLE
            }
        case ACTIONS.CLOSE_ERRORS_MODAL.main:
            return {
                type: null,
                message: ''
            }
        default:
            return state
    }
}

export const closeErrorsModal = () => ({
    type: ACTIONS.CLOSE_ERRORS_MODAL.main
})
