import React from 'react'
import {Loader} from '../Loader'

import './PageLoader.scss'

export default function PageLoader() {
    return (
        <div className="page-loader-container">
            <Loader size="large" />
        </div>
    )
}
