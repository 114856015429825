import React from 'react'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

// @ts-ignore
import {clearEntityIDS} from 'avoapp-react-common/dist/redux/localConfigs'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'

import './AccountDeleted.scss'

interface AccountDeletedProps {
    clearEntityIDS: () => void
}

const AccountDeleted: React.FC<AccountDeletedProps> = ({clearEntityIDS}) => {
    const history = useHistory()

    setTimeout(() => {
        clearEntityIDS()
        history.push('/select-entity')
    }, 2000)

    return (
        <div className="wrapper">
            <div>
                <img
                    className="h-auto w-full"
                    src={logo}
                    alt="Advocatus"
                />
            </div>
            <div className="info-box">Contul tău Advocatus a fost șters cu succes.</div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    clearEntityIDS: () => dispatch(clearEntityIDS())
})

export default connect(undefined, mapDispatchToProps)(AccountDeleted)
