import React, {useEffect} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'

// @ts-ignore
import {activateAccount} from 'avoapp-react-common/dist/redux/auth'
import {connect} from 'react-redux'

import {Loader} from 'components/Loader'

// @ts-ignore
import logo from '../../assets/images/logo-advocatus.png'

import './AccountActivation.scss'

interface AccountActivationProps {
    isLoading: boolean
    accountActivated: boolean
    activateAccount: (key: string) => void
}

function AccountActivation({isLoading, accountActivated, activateAccount}: AccountActivationProps) {
    const history = useHistory()
    const {activationCode} = useParams<{activationCode: string}>()

    useEffect(() => {
        if (activationCode) {
            activateAccount(activationCode)
        }
    }, [activateAccount, activationCode])

    useEffect(() => {
        if (accountActivated) {
            history.push('/account-phone-activation')
        }
    }, [accountActivated, history])

    return (
        <div className="account-activation-container">
            <div className="main-container">
                <div className="header">
                    <img
                        src={logo}
                        className="logo"
                        alt="Advocatus"
                    />
                    <h1 className="title">Activare cont</h1>
                </div>
                {activationCode ? (
                    <div className="content">
                        {isLoading ? (
                            <LoadingComponent />
                        ) : accountActivated ? (
                            <AccountActivated />
                        ) : (
                            <AccountActivationFail />
                        )}
                    </div>
                ) : (
                    <div className="content">Link-ul de confirmare a fost trimis pe adresa introdusă</div>
                )}
            </div>
        </div>
    )
}

function LoadingComponent() {
    return (
        <div className="account-activation">
            <p className="activation-message">Așteaptă salvarea răspunsului tău</p>
            <Loader size="large" />
        </div>
    )
}

function AccountActivated() {
    return (
        <div className="account-activation">
            <p className="activation-message">
                Contul tau a fost confirmat! Poți începe monitorizarea dosarelor tale de instanță chiar acum.
            </p>
            <Link
                to="/login"
                className="login-link">
                Mergi la login
            </Link>
        </div>
    )
}

function AccountActivationFail() {
    return (
        <div className="account-activation">
            <p className="activation-message">
                <span>Oups! </span>Pare că linkul de activare a expirat. <br />
                Am retrimis chiar acum un alt link pe adresa ta de email pentru a putea fi validat.
            </p>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    accountActivated: state.auth.accountActivated,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
    activateAccount: (key: string) => dispatch(activateAccount({key: key}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
