import React, {useCallback} from 'react'

import {CountryData, default as ReactPhoneInput} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {isEmpty} from 'lodash'

// @ts-ignore
import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './PhoneInput.scss'

interface PhoneInputProps {
    value: string
    name: string
    label?: string
    onChange: (
        value: string,
        data: CountryData | object,
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => void
    size?: 'small' | 'medium' | 'large'
    fullWidth?: boolean
    errors: any
}

export default function PhoneInput({
    label,
    value,
    onChange,
    size = 'medium',
    fullWidth,
    errors,
    name
}: PhoneInputProps) {
    const renderErrors = useCallback(() => {
        if (errors && !isEmpty(errors[name])) {
            return errors[name].map((error: any, errorIdx: number) => (
                <InputError
                    key={errorIdx}
                    message={getErrorMessage(error, name)}
                />
            ))
        }
    }, [errors, name])

    return (
        <div>
            {label && <label className="avo-phone-input-label">{label}</label>}
            <ReactPhoneInput
                value={value}
                onChange={onChange}
                containerClass="avo-phone-input-container"
                inputClass={`avo-phone-input ${size} ${fullWidth ? 'full-width' : ''}`}
                country="ro"
            />
            {renderErrors()}
        </div>
    )
}
