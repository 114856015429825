import React from 'react'

import './NoAccount.scss'

export default function NoAccount() {
    return <></>

    // return (
    //     <div className="no-account-container">
    //         <div className="separator-container">
    //             <div className="line-container">
    //                 <div className="line" />
    //             </div>
    //             <div className="text-container">
    //                 <span className="text">Nu ai cont incă?</span>
    //             </div>
    //         </div>
    //         <div className="create-account-link-container">
    //             <div>
    //                 <Link
    //                     className="create-account-link"
    //                     to="/register">
    //                     Creează un cont nou acum
    //                 </Link>
    //             </div>
    //         </div>
    //     </div>
    // )
}
