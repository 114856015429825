import * as Yup from 'yup'

import {isNull} from 'lodash'

import {successBonusTypes} from '../../utils/types'

export const validationSchema = {
    create: Yup.object().shape({
        projectsIds: Yup.array()
            .label('Proiecte')
            .min(1, 'Selectați cel puțin un proiect!')
            .nullable()
            .required('Proiectul este obligatoriu!'),
        clientsIds: Yup.array()
            .label('Clienți')
            .min(1, 'Selectați cel puțin un client!')
            .required('Selectați cel puțin un client!'),
        internalSeriesId: Yup.object().label('Serie internă').required('Seria internă este obligatorie!'),
        barSeriesId: Yup.object().label('Serie barou').required('Seria barou este obligatorie!'),
        name: Yup.string().label('Nume contract').required('Numele contractului este obligatoriu!'),
        legalObject: Yup.string().label('Obiect legal'),
        signedDate: Yup.date().label('Data semnare').nullable(),
        startDate: Yup.date()
            .label('Data producere efecte')
            .nullable()
            .required('Data producere efecte este obligatorie!'),
        endDate: Yup.date().label('Data încetare').nullable(),
        hasAmountToRecover: Yup.boolean(),
        amountToRecover: Yup.string()
            .label('Suma')
            .when('hasAmountToRecover', {
                is: true,
                then: (schema) => schema.required('Suma este obligatorie dacă "Sumă de recuperat" este bifat!')
            }),
        amountToRecoverCurrency: Yup.object()
            .label('Moneda')
            .nullable()
            .when('hasAmountToRecover', {
                is: true,
                then: (schema) => schema.required('Moneda este obligatorie dacă "Sumă de recuperat" este bifat!')
            }),
        billingPaymentMethod: Yup.object().label('Tip facturare').required('Tipul de facturare este obligatoriu!'),
        billingAmount: Yup.string().label('Suma').required('Suma este obligatorie!'),
        billingCurrency: Yup.object().label('Moneda').required('Moneda este obligatorie!'),
        billingTaxPercentage: Yup.object().label('Taxă adăugată').required('Taxa adăugată este obligatorie!'),
        isRecurring: Yup.boolean(),
        billingRecurringMonths: Yup.string().when('isRecurring', {
            is: true,
            then: (schema) => schema.required('Lunile de recurență sunt obligatorii dacă "Recurent" este bifat!')
        }),
        hasSuccessBonus: Yup.boolean(),
        billingSuccessBonusType: Yup.object()
            .label('Tip')
            .nullable()
            .when('hasSuccessBonus', {
                is: true,
                then: (schema) => schema.required('Tipul este obligatoriu dacă "Onorariu succes" este bifat!')
            }),
        billingSuccessBonusValue: Yup.string()
            .label('Număr')
            .when('hasSuccessBonus', {
                is: true,
                then: (schema) => schema.required('Numărul este obligatoriu dacă "Onorariu succes" este bifat!')
            }),
        billingSuccessBonusCurrency: Yup.object()
            .label('Moneda')
            .nullable()
            .when('billingSuccessBonusType', {
                is: (val) => !isNull(val) && val.value === successBonusTypes.VALUE.value,
                then: (schema) =>
                    schema.required(`Moneda este obligatorie dacă "Tip" este "${successBonusTypes.VALUE.label}"!`)
            }),
        templateId: Yup.object().label('Șablon').nullable().required('Șablonul este obligatoriu!')
    }),
    update: Yup.object().shape({
        clientsIds: Yup.array()
            .label('Clienți')
            .min(1, 'Selectați cel puțin un client!')
            .required('Selectați cel puțin un client!'),
        name: Yup.string().label('Nume contract').required('Numele contractului este obligatoriu!'),
        legalObject: Yup.string().label('Obiect legal'),
        signedDate: Yup.date().label('Data semnare').nullable(),
        startDate: Yup.date()
            .label('Data producere efecte')
            .nullable()
            .required('Data producere efecte este obligatorie!'),
        endDate: Yup.date().label('Data încetare').nullable(),
        hasAmountToRecover: Yup.boolean(),
        amountToRecover: Yup.string()
            .label('Suma')
            .when('hasAmountToRecover', {
                is: true,
                then: (schema) => schema.required('Suma este obligatorie dacă "Sumă de recuperat" este bifat!')
            }),
        amountToRecoverCurrency: Yup.object()
            .label('Moneda')
            .nullable()
            .when('hasAmountToRecover', {
                is: true,
                then: (schema) => schema.required('Moneda este obligatorie dacă "Sumă de recuperat" este bifat!')
            }),
        billingPaymentMethod: Yup.object().label('Tip facturare').required('Tipul de facturare este obligatoriu!'),
        billingAmount: Yup.string().label('Suma').required('Suma este obligatorie!'),
        billingCurrency: Yup.object().label('Moneda').required('Moneda este obligatorie!'),
        billingTaxPercentage: Yup.object().label('Taxă adăugată').required('Taxa adăugată este obligatorie!'),
        isRecurring: Yup.boolean(),
        billingRecurringMonths: Yup.string().when('isRecurring', {
            is: true,
            then: (schema) => schema.required('Lunile de recurență sunt obligatorii dacă "Recurent" este bifat!')
        }),
        hasSuccessBonus: Yup.boolean(),
        billingSuccessBonusType: Yup.object()
            .label('Tip')
            .nullable()
            .when('hasSuccessBonus', {
                is: true,
                then: (schema) => schema.required('Tipul este obligatoriu dacă "Onorariu succes" este bifat!')
            }),
        billingSuccessBonusValue: Yup.string()
            .label('Număr')
            .when('hasSuccessBonus', {
                is: true,
                then: (schema) => schema.required('Numărul este obligatoriu dacă "Onorariu succes" este bifat!')
            }),
        billingSuccessBonusCurrency: Yup.object()
            .label('Moneda')
            .nullable()
            .when('billingSuccessBonusType', {
                is: (val) => !isNull(val) && val.value === successBonusTypes.VALUE.value,
                then: (schema) =>
                    schema.required(`Moneda este obligatorie dacă "Tip" este "${successBonusTypes.VALUE.label}"!`)
            })
    })
}
