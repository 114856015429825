import {LifebuoyIcon} from '@heroicons/react/24/outline'

// @ts-ignore
import {isCurrentNavigationItem} from '../../utils'

class NavigationItem {
    name: string
    path: string
    icon: any
    current: boolean

    constructor(name: string, path: string, icon: any) {
        this.name = name
        this.path = path
        this.icon = icon
        this.current = isCurrentNavigationItem(path)
    }
}

export const navigation = [
    new NavigationItem('Contul meu', '/settings/profile', undefined),
    new NavigationItem('Companie', '/company', undefined)
]

export const secondaryNavigation = [new NavigationItem('Asistență', '/support', LifebuoyIcon)]
