import {ArrowRightOnRectangleIcon, UserCircleIcon} from '@heroicons/react/24/outline'
import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'

import {find} from 'lodash'

// @ts-ignore
import {logout} from 'avoapp-react-common/dist/redux/auth'
// @ts-ignore
import {clearEntityIDS, clearTaskTimeLogID} from 'avoapp-react-common/dist/redux/localConfigs'
import {connect} from 'react-redux'

interface ProfileSectionProps {
    user: any
    selectedEntity: any
    logout: () => void
    clearEntityIDS: () => void
    clearTaskTimeLogID: () => void
}

const ProfileSection = ({user, selectedEntity, logout, clearEntityIDS, clearTaskTimeLogID}: ProfileSectionProps) => {
    const handleLogout = useCallback(() => {
        logout()
        clearEntityIDS()
        clearTaskTimeLogID()
    }, [clearEntityIDS, clearTaskTimeLogID, logout])

    return (
        <div className="profile-section">
            <div className="flex-shrink-0 w-full">
                <div className="flex justify-between">
                    <div className="flex items-center">
                        <UserCircleIcon
                            aria-hidden="true"
                            className="h-10 w-10 text-white"
                        />
                        <div className="ml-3">
                            <p className="user-name">
                                {user.first_name} {user.last_name}
                            </p>
                            {selectedEntity && (
                                <>
                                    <p className="selected-entity">{selectedEntity.name}</p>
                                    <Link
                                        to="/select-entity"
                                        className="change-entity-link">
                                        Schimbă compania
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="logout-button-container">
                        <ArrowRightOnRectangleIcon
                            aria-hidden="true"
                            className="logout-icon"
                            onClick={handleLogout}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    const selectedEntity = find(state.entityProfiles.belongingEntities, ['id', state.localConfigs.selectedEntityID])

    return {
        user: state.users.user,
        selectedEntity: selectedEntity
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    logout: () => dispatch(logout()),
    clearEntityIDS: () => dispatch(clearEntityIDS()),
    clearTaskTimeLogID: () => dispatch(clearTaskTimeLogID())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection)
