import React from 'react'

import './InputError.scss'

interface InputErrorProps {
    message: string
}

export default function InputError({message}: InputErrorProps) {
    return (
        <div className="input-error-container">
            <p className="input-error-text">{message}</p>
        </div>
    )
}
