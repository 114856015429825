import React from 'react'

import {connect} from 'react-redux'
import {errorsModalTypes} from 'redux/errorsModal'

import ErrorsModal from '../ErrorsModal'
import {ErrorModalProps} from '../model'

export const Error500Modal = ({open}: ErrorModalProps) => {
    return (
        <ErrorsModal
            open={open}
            contactAvailable
            messageContent={() => (
                <p className="avo-errors-modal-message">
                    A apărut o eroare, te rugăm să încerci mai târziu. Dacă eroarea persistă, te rugăm sa ne contactezi!
                </p>
            )}
        />
    )
}

const mapStateToProps = (state: any) => ({
    // TODO: REMOVE THIS AND MAKE IT WORK PROPPERLY
    open: state.errorsModal.type === errorsModalTypes.ERROR_500 + '1'
})

export default connect(mapStateToProps)(Error500Modal)
