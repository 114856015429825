import React, {ReactNode} from 'react'

import {FormikTouched} from 'formik'

import _ from 'lodash'

// @ts-ignore
import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './Checkbox.scss'

interface CheckboxProps {
    label?: string | (() => ReactNode | string)
    checked: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
    size?: 'small' | 'medium' | 'large'
    labelLeft?: boolean
    fullWidth?: boolean
    name: string
    errors?: any
    frontendErrors?: any
    className?: string
}

export default function Checkbox({
    label,
    checked,
    onChange,
    onBlur,
    size,
    labelLeft,
    fullWidth,
    name,
    errors,
    frontendErrors,
    touched,
    className
}: CheckboxProps) {
    const snakeCaseName = _.snakeCase(name)
    const camelCaseName = _.camelCase(name)

    const hasErrors =
        (errors && !_.isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !_.isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !_.isEmpty(errors[snakeCaseName])
            ? getErrorMessage(_.head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div
            className={
                'avo-checkbox-container ' +
                `${size || 'medium'} ` +
                `${fullWidth ? 'full-width' : ''} ` +
                `${labelLeft ? 'label-left' : ''} ` +
                `${className || ''}`
            }>
            <div className="avo-checkbox-center">
                {label && labelLeft && (
                    <label
                        htmlFor={name}
                        className="avo-checkbox-label">
                        {_.isString(label) ? label : label()}
                    </label>
                )}
                <input
                    id={name}
                    name={name}
                    checked={checked}
                    type="checkbox"
                    className="checkbox"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                {label && !labelLeft && (
                    <label
                        htmlFor={name}
                        className="avo-checkbox-label">
                        {_.isString(label) ? label : label()}
                    </label>
                )}
            </div>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
