import React, {useMemo} from 'react'

import _ from 'lodash'

// @ts-ignore
import {entityProfilesStatus} from 'avoapp-react-common/dist/constants'

import './EntityProfileDetails.scss'

interface EntityProfileDetailsProps {
    entityProfile: any
}

export default function EntityProfileDetails({entityProfile}: EntityProfileDetailsProps) {
    const profileStatus = useMemo(() => {
        const profileStatus = entityProfilesStatus[_.toUpper(entityProfile.status)]
        return profileStatus?.label ?? '-'
    }, [entityProfile.status])

    return (
        <>
            <div className="entity-profile-details-card">
                <div className="entity-profile-name-container">
                    <h3 className="entity-profile-name">{entityProfile.full_name}</h3>
                </div>
                <div className="entity-profile-details-card-content-container">
                    <p className="entity-profile-property">
                        <span className="entity-profile-property-key">Email: </span>
                        {entityProfile.email}
                    </p>
                    <p className="entity-profile-property">
                        <span className="entity-profile-property-key">Telefon: </span>
                        {entityProfile.phone}
                    </p>
                    <p className="entity-profile-property">
                        <span className="entity-profile-property-key">Status: </span>
                        {profileStatus}
                    </p>
                </div>
            </div>
        </>
    )
}
