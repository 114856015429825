import React, {Fragment, ReactNode, useCallback} from 'react'

import {Dialog, Transition} from '@headlessui/react'

import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {closeModal} from 'redux/modals'

import './Modal.scss'

interface ModalProps {
    open: boolean
    children: ReactNode
    title?: string
    hideHeader?: boolean
    onClose?: () => void
    closeModal: () => void
    disableClosing?: boolean
    maxWidth?: string | number
    minWidth?: string | number
}

const Modal = ({
    open,
    title,
    hideHeader,
    children,
    onClose,
    closeModal,
    disableClosing,
    maxWidth,
    minWidth
}: ModalProps) => {
    const handleClose = useCallback(() => {
        if (onClose !== undefined) {
            onClose()
        }

        if (!disableClosing) {
            closeModal()
        }
    }, [closeModal, disableClosing, onClose])

    return (
        <Transition.Root
            show={open}
            as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={handleClose}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-200"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className={`avo-modal-content-container ${hideHeader ? 'header-hidden' : ''}`}
                            style={{maxWidth: maxWidth, minWidth: minWidth}}>
                            {!hideHeader && (
                                <Dialog.Title
                                    as="h3"
                                    className="avo-modal-title">
                                    {title}
                                </Dialog.Title>
                            )}
                            <div className="avo-modal-content">{children}</div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

Modal.defaultProps = {
    maxWidth: '42rem',
    minWidth: '40rem'
}

export default connect(null, mapDispatchToProps)(Modal)
