import * as Yup from 'yup'

import {personTypes} from '../../utils/types'

export const validationSchema = {
    initialInformations: Yup.object().shape({
        type: Yup.object().label('Tip client').required('Tipul clientului e obligatoriu!'),
        name: Yup.string().label('Nume').required('Numele este obligatoriu!')
        // managers: Yup.array()
        //     .label('Manageri')
        //     .min(1, 'Selectează cel puțin un manager')
        //     .required('Managerii sunt obligatorii!'),
        // collaborators: Yup.array().label('Colaboratori').nullable()
    }),
    generalInformations: Yup.object().shape({
        type: Yup.lazy((value) => {
            switch (typeof value) {
                case 'object':
                    return Yup.object()
                default:
                    return Yup.string()
            }
        }),
        vatCode: Yup.string().when('type', {
            is: (val) =>
                val === personTypes.COMPANY.value ||
                (typeof val === 'object' && val.value === personTypes.COMPANY.value),
            then: (schema) => schema.label('CUI'),
            otherwise: (schema) => schema.label('CNP')
        }),
        businessRegistrationNumber: Yup.string().label('Registrul comerțului'),
        email: Yup.string().label('Email').email('Adresa de email nu e validă!'),
        addAddress: Yup.boolean(),
        country: Yup.object()
            .label('Țara')
            .nullable()
            .when('addAddress', {
                is: true,
                then: (schema) => schema.required('Țara este obligatorie')
            }),
        county: Yup.mixed()
            .label('Județ')
            .when('addAddress', {
                is: true,
                then: (schema) => schema.required('Județul este obligatoriu')
            }),
        locality: Yup.string()
            .label('Localitate / Oraș')
            .when('addAddress', {
                is: true,
                then: (schema) => schema.required('Câmpul Localitate / Oraș este obligatoriu!')
            }),
        postalCode: Yup.string().label('Cod poștal'),
        address: Yup.string()
            .label('Adresa')
            .when('addAddress', {
                is: true,
                then: (schema) => schema.required('Adresa este obligatorie!')
            }),
        addressDescription: Yup.string()
            .label('Descriere adresă')
            .when('addAddress', {
                is: true,
                then: (schema) => schema.required('Descrierea adresei este obligatorie!')
            }),
        addBankAccount: Yup.boolean(),
        iban: Yup.string()
            .label('IBAN')
            .when('addBankAccount', {
                is: true,
                then: (schema) => schema.required('IBAN-ul este obligatoriu!')
            }),
        currency: Yup.object()
            .label('Moneda')
            .nullable()
            .when('addBankAccount', {
                is: true,
                then: (schema) => schema.required('Moneda este obligatorie!')
            }),
        bankName: Yup.string()
            .label('Banca')
            .when('addBankAccount', {
                is: true,
                then: (schema) => schema.required('Banca este obligatorie!')
            }),
        swiftCode: Yup.string().label('Cod SWIFT'),
        bankAccountDescription: Yup.string()
            .label('Descriere cont bancar')
            .when('addBankAccount', {
                is: true,
                then: (schema) => schema.required('Descrierea contului bancar este obligatorie!')
            })
    })
}
