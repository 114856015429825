import {ronCurrency} from './constants'

export const environmentTypes = {
    DEV: 'development',
    PROD: 'production'
}

export const sourceTypes = {
    PORTAL_JUST: 'Portal Just',
    BPI: 'BPI',
    MANUAL: 'Dosar Manual'
}

export const categoryTypes = {
    // CLIENTS
    MY_CLIENTS: 'MY_CLIENTS',
    ACTIVE_CLIENTS: 'ACTIVE_CLIENTS',
    ARCHIVED_CLIENTS: 'ARCHIVED_CLIENTS',
    OPPONENTS: 'OPPONENTS',

    // CLIENT DETAILS
    CLIENT_INFO: 'CLIENT_INFO',
    EXTERNAL_DATA: 'EXTERNAL_DATA',
    TASKS: 'TASKS',
    CONTRACTS: 'CONTRACTS',
    DOCUMENTS: 'DOCUMENTS',
    EXPENSES: 'EXPENSES',
    INVOICES: 'INVOICES',
    HISTORY: 'HISTORY',

    // CLIENT INFO
    GENERAL: 'GENERAL',
    CONTACT_PERSONS: 'CONTACT_PERSONS',
    NOTES: 'NOTES',

    // EXTERNAL DATA
    PORTAL_JUST: 'PORTAL_JUST',

    // TASKS
    ACTIVE_TASKS: 'ACTIVE_TASKS',
    FINISHED_TASKS: 'FINISHED_TASKS',
    ACTIVITY_TRACKING: 'ACTIVITY_TRACKING'
}

export const personTypes = {
    PERSON: {
        value: 'person',
        label: 'Persoană fizică'
    },
    COMPANY: {
        value: 'company',
        label: 'Persoană juridică'
    }
}

export const projectTypes = {
    COURT: {
        value: 'Instanță',
        label: 'Instanță'
    },
    CONSULTANCY: {
        value: 'Consultanță',
        label: 'Consultanță'
    }
}

export const roleTypes = {
    COLLABORATOR: {
        value: 'internal_collaborator',
        label: 'Colaborator'
    },
    MANAGER: {
        value: 'manager',
        label: 'Manager'
    },
    CUSTOM: {
        value: 'custom',
        label: 'Personalizat'
    }
}

export const anyRoleType = {
    value: 'any',
    label: 'Orice rol'
}

export const logsOrderingOptions = {
    asc: 'created',
    desc: '-created'
}

export const timeErrors = {
    stop_before_start: {
        key: 'stop_before_start',
        message: 'Inceputul intervalului nu poate fi înainte de finalul intervalului'
    },
    interval_too_long: {
        key: 'interval_too_long',
        message: 'Inceputul și finalul intervalului nu poate fi în zile diferite'
    },
    missing_value: {
        key: 'missing_value',
        message: 'Inceputul și finalul intervalului sunt obligatorii'
    }
}

export const documentTemplateTypes = {
    PROJECT: {
        value: 'project',
        label: 'Proiect'
    },
    CONTRACT: {
        value: 'contract',
        label: 'Contract'
    },
    ADDENDUM: {
        value: 'addendum',
        label: 'Act Adițional'
    },
    EMPOWERMENT: {
        value: 'empowerment',
        label: 'Împuternicire'
    },
    ARCHIVE: {
        value: 'archive',
        label: 'Arhivă'
    },
    INVOICE: {
        value: 'invoice',
        label: 'Factură'
    },
    PROFORMA: {
        value: 'proforma',
        label: 'Proformă'
    },
    RECEIPT: {
        value: 'receipt',
        label: 'Chitanță'
    }
}

export const documentSeriesTypes = {
    CONTRACT_BAR: {
        value: 'contract_bar',
        label: 'Contracte - Registru Barou'
    },
    CONTRACT_INTERNAL: {
        value: 'contract_internal',
        label: 'Contracte - Registru intern'
    },
    EMPOWERMENT_BAR: {
        value: 'empowerment_bar',
        label: 'Împuterniciri - Registru barou'
    },
    EMPOWERMENT_INTERNAL: {
        value: 'empowerment_internal',
        label: 'Împuterniciri - Registru intern'
    },
    FISCAL_INVOICE: {
        value: 'fiscal_invoice',
        label: 'Facturi fiscale'
    },
    PROFORMA_INVOICE: {
        value: 'proforma_invoice',
        label: 'Facturi proforme'
    },
    RECEIPT: {
        value: 'receipt',
        label: 'Chitanțe'
    }
}

export const currencies = {
    RON: ronCurrency,
    EUR: {value: 'EUR', label: 'EUR'},
    USD: {value: 'USD', label: 'USD'},
    GBP: {value: 'GBP', label: 'GBP'}
}

export const successBonusTypes = {
    VALUE: {value: 'value', label: 'Sumă fixă'},
    PERCENT: {value: 'percent', label: 'Procent'}
}

export const billingPaymentMethods = {
    FIXED_AMOUNT: {value: 'fixed_amount', label: 'Tarif paușal (fix)'},
    HOURLY_BLENDED: {value: 'hourly_blended', label: 'Tarif orar'},
    HOURLY_BY_SENIORITY: {value: 'hourly_by_seniority', label: 'Tarif senioritate'}
}

export const taxPercentageOptions = {
    19: {value: 19, label: '19%'},
    9: {value: 9, label: '9%'},
    5: {value: 5, label: '5%'},
    0: {value: 0, label: '0%'}
}

export const unitOptions = {
    BUC: {label: 'buc.', value: 'buc.'},
    ORE: {label: 'ore', value: 'ore'}
}

export const discountTypes = {
    INVOICE: {label: 'Total factură', value: 'invoice'},
    ENTRY: {label: 'Produs/Serviciu individual', value: 'entry'}
}

export const calcMethods = {
    SUM: {label: 'Sumă', value: 'value'},
    PERCENT: {label: 'Procent', value: 'percent'}
}
